/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
require('jquery-ui/dist/themes/smoothness/jquery-ui.css');

import $ from 'jquery';

window.jQuery = $;
window.$ = $;
// const $ = global.$ = require('jquery');

require('jquery-ui');
require('jquery-ui/ui/effect');
require('jquery-ui/ui/effects/effect-highlight');

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');


// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

/*

$(document).ready(function() {
    $('[data-bs-toggle="popover"]').popover();
});

*/

// start the Stimulus application
import './bootstrap';
